import SignatureCanvas from 'react-signature-canvas';
import { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';
import axios from 'axios';

function App() {
  const canvas = useRef(null);
  const [formValues, setFormValues] = useState({
    name: '',
    date: new Date().toISOString().slice(0, 10),
    price: 1,
    number: 1,
    signature: '',
    email: '',
    address: '',
  });
  const [signatureDone, setSignatureDone] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const changeVals = (target, id) => {
    const copy = { ...formValues };
    copy[id] = target.value;
    setFormValues(copy);
  };

  const submitForm = async () => {
    setDisabled(true);
    const {
      name,
      email,
      date,
      price: numericPrice,
      number,
      address,
      signature,
    } = formValues;
    if (name === '') {
      toast.error('Uzupełnij imię i nazwisko (fill in name and surname)');
      return;
    }
    if (address === '') {
      toast.error('Uzupełnij adres (fill in address)');
      return;
    }
    if (email === '') {
      toast.error('Uzupełnij email (fill in email)');
      return;
    }
    if (numericPrice < 1) {
      toast.error('Cena musi być dodatnia (Price must be positive)');
      return;
    }
    if (number < 1 || number >= 25) {
      toast.error(
        'Jednocześnie można dodać minimalnie 1 i maksymalnie 25 sztuk (You can add 1-25 units at once)'
      );
      return;
    }

    const data = [];

    for (let i = 0; i < parseInt(number); i++) {
      const obj = {
        name,
        date: date.split('-').reverse().join('-'),
        price: `${numericPrice} PLN`,
        address,
        signature,
        uid: uuid().replaceAll('-', '').toUpperCase().slice(0, 6),
      };
      data.push(obj);
    }

    const fetching = await fetch(
      `https://szmery-general.onrender.com/stealz/contracts/issue-and-send/${email}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    ).then((data) => data.json());
    setDisabled(false);
    toast.success(fetching.message);
    setFormValues({
      name: '',
      date: new Date().toISOString().slice(0, 10),
      price: 1,
      number: 1,
      signature: '',
      address: '',
      email: '',
    });

    window.location.reload()
  };

  return (
    <div className='App'>
      <main className='rounded bg-stone-100 min-h-screen pt-[15vh] pb-[10vh]'>
        <section className='bg-white rounded-xl shadow-xl w-full xl:w-1/2 mx-auto pt-8 pb-20'>
          <img
            alt='sneakers-stealz-logo'
            src='/sneakers_stealz.jpg'
            className='w-[300px] mx-auto'
          />
          <div className='flex flex-col items-center justify-center space-y-2 pt-2'>
            <Field
              label={'Data (date)'}
              id='date'
              type='date'
              changeVals={changeVals}
              formValues={formValues}
            />
            <Field
              label={'Imię i nazwisko (name and surname)'}
              id='name'
              changeVals={changeVals}
              formValues={formValues}
            />
            <Field
              label={'Email (email address)'}
              id='email'
              type='email'
              changeVals={changeVals}
              formValues={formValues}
            />
            <Field
              label={'Pełen adres (full address)'}
              id='address'
              changeVals={changeVals}
              formValues={formValues}
            />
            <Field
              label={'Cena za sztukę w PLN (price per unit in PLN)'}
              id='price'
              type='number'
              changeVals={changeVals}
              formValues={formValues}
            />
            <Field
              label={'Ilość (Quantity)'}
              id='number'
              type='number'
              changeVals={changeVals}
              formValues={formValues}
            />
          </div>
          <div className='flex flex-col items-center justify-center mt-20 space-y-8'>
            <p className='underline font-medium'>
              Miejsce na Twój podpis:
            </p>
            <SignatureCanvas
              ref={canvas}
              canvasProps={{
                width: 300,
                height: 150,
                className: 'sigCanvas bg-slate-100',
              }}
            />

            <div className='flex items-center justify-center space-x-4'>
              <button
                className='button button-side'
                onClick={() => {
                  canvas.current.clear();
                  setSignatureDone(false);
                }}>
                Wyczyść (Clear)
              </button>
              <button
                className='button button-side'
                onClick={() => {
                  setFormValues({
                    ...formValues,
                    signature: canvas.current.toDataURL(),
                  });
                  setSignatureDone(true);
                }}>
                Podpisz (Sign)
              </button>
            </div>
          </div>
          {signatureDone ? (
            <div className='flex items-center justify-center pt-12'>
              <button
                className='button button-main'
                onClick={submitForm}
                disabled={disabled}>
                Wygeneruj umowę (Generate contract)
              </button>
            </div>
          ) : (
            <></>
          )}
        </section>
      </main>
    </div>
  );
}

export default App;

const Field = ({ label, type, id, changeVals, formValues }) => {
  return (
    <>
      <label htmlFor={id} className='text-lg pt-4'>
        {label}
      </label>
      <input
        type={type || 'text'}
        value={formValues[id]}
        id={id}
        onChange={(e) => changeVals(e.target, id)}
        className='min-w-3/4 lg:min-w-[500px] border-2 rounded-lg text-center py-1 px-4'
      />
    </>
  );
};
